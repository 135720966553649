// src/api.js
import axios from 'axios';
import configData from '../src/config';

let Lantern = localStorage.getItem('Lantern-account');
let tokenObj = JSON.parse(Lantern);
let token = tokenObj.token;

export const getCategories = async () => {
    const response = await axios.get(configData.API_SERVER + `categories`, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};

export const getGroups = async () => {
    const response = await axios.get(configData.API_SERVER + `groups`, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};
export const GetGroupById = async (id) => {
    const response = await axios.get(configData.API_SERVER + `groups/` + id, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
}
export const UpdateGroupById = async (data, id) => {
    const response = await axios.put(`${configData.API_SERVER}` + "groups/" + id, data, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
}

export const verifyEmail = async (data) => {
    const response = await axios.post(`${configData.BASE_API}` + "/password/recovery", data, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
}

export const resetPassword = async (data) => {
    const response = await axios.post(`${configData.BASE_API}` + "/password/reset", data, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
}

export const DeleteGroup = async (id) => {
    const response = await axios.delete(configData.API_SERVER + `groups/` + id, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
}

export const getEstablishments = async () => {


    const response = await axios.get(configData.API_SERVER + 'establishments', {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};

export const createCertificate = async (user) => {


    let formData = new FormData();
    formData.append('path_name', user.path_name);
    formData.append('path_description', user.path_description);
    formData.append('path_image', user.file);
    formData.append('path_image_tab', user.file1);
    formData.append('path_image_mob', user.file2);
    formData.append('path_price', user.path_price);
    formData.append('path_lead', user.path_lead);
    formData.append('benefits', JSON.stringify(user.benefits));
    formData.append('path_group', user.path_group);
    formData.append('category_id', user.category_id);
    if (user.type === "Taught with a partner") {
        formData.append('path_partner', [user.etablissement]);
    }
    formData.append('path_status', user.path_status);
    formData.append('type', user.type);

    const response = await axios.post(`${configData.API_SERVER}` + "paths", formData, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};


export const getInstructors = async () => {


    try {
        const response = await axios.get(configData.API_SERVER + 'users/instructors', {
            headers: { 'Authorization': JSON.parse(token) }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getPathById = async (id) => {


    try {
        const response = await axios.get(configData.API_SERVER + `paths/${id}`, {
            headers: { 'Authorization': JSON.parse(token) }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const handleWithFile = (cohort, socialMediaInputs, sendRequest) => {
    const formData = new FormData();
    var socialsArray = socialMediaInputs;
    var socialsJSON = typeof socialsArray === 'string' ? socialsArray : JSON.stringify(socialsArray);

    formData.append('path_description', cohort.path_description);
    formData.append('path_image', cohort.file);
    formData.append('path_name', cohort.path_name);
    formData.append('path_image_tab', cohort.file1);
    formData.append('path_image_mob', cohort.file2);
    formData.append('path_price', cohort.path_price);
    formData.append('category_id', cohort.category_id);
    formData.append('path_group', cohort.path_group);
    formData.append('benefits', socialsJSON);
    formData.append('path_status', cohort.path_status);
    cohort.path_partner && formData.append('path_partner', cohort.path_partner);
    formData.append('path_lead', cohort.path_lead);

    sendRequest(formData, token);
};

export const updatePathUnrealEngin = (cohort, boolean, sendRequest, id) => {
    const formData = new FormData();
    console.log(boolean);
    formData.append('path_partner', boolean ? 0 : 999);

    sendRequest(formData, token, id);
};

export const handleWithoutFile = (cohort, socialMediaInputs, sendRequest) => {
    const formData = new FormData();
    var socialsArray = socialMediaInputs;
    var socialsJSON = typeof socialsArray === 'string' ? socialsArray : JSON.stringify(socialsArray);
    formData.append('path_description', cohort.path_description);

    formData.append('path_name', cohort.path_name);
    formData.append('path_price', cohort.path_price);
    formData.append('category_id', cohort.category_id);
    formData.append('path_group', cohort.path_group);
    formData.append('benefits', socialsJSON);
    cohort.path_partner && formData.append('path_partner', cohort.path_partner);
    formData.append('path_status', cohort.path_status);
    formData.append('path_lead', cohort.path_lead);

    sendRequest(formData, token);
};

export const getUsers = async () => {
    const response = await axios.get(configData.API_SERVER + `users`, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    }); const formData = new FormData();

    return response.data;
};

export const getUserById = async (id) => {
    const response = await axios.get(configData.API_SERVER + `users/` + id, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};

export const getPathDraft = async () => {
    const response = await axios.get(configData.API_SERVER + 'paths/drafts', {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};

export const getSessions = async () => {
    const response = await axios.get(configData.API_SERVER + 'sessions', {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};


export const getNotifications = async () => {
    try {
        const response = await axios.post(
            configData.API_SERVER + 'notifications',
            {},
            {
                headers: {
                    'Authorization': JSON.parse(token)
                }
            }
        );
        return response.data;
    } catch (error) {
        console.log('Error fetching notifications:', error);
        throw error; // Optional: Handle error as needed
    }
};





const fetchAllIds = async () => {
    const instructorsIdsResponse = await axios.get(`${configData.API_SERVER}establishments/0/instructors`, {
        headers: {
            'Authorization': token
        }
    });

    let instructorIds = instructorsIdsResponse.data.map(instructor => instructor.id);
    let allIds = [...instructorIds];
    return [...new Set(allIds)];
};

export const affectUserToUnrealEngin = async (userId) => {
    let Ids = await fetchAllIds()
    const data = { 'users': [...Ids, userId] };
    const response = await axios.post(`${configData.API_SERVER}` + "establishments/0/users", data, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;

};

export const deleteUserToUnrealEngin = (userId) => {
    try {
        const data = { 'users': [userId] };
        const response = axios.delete(`${configData.API_SERVER}establishments/0/users`, {
            headers: {
                'Authorization': JSON.parse(token)
            },
            data: data
        });
        return response.data;
    } catch (error) {
        console.log('Error deleting user from Unreal Engine:', error);
        throw error;
    }
};

export const UpdateUser = async (data, id) => {
    const response = await axios.put(`${configData.API_SERVER}` + "users/" + id, data, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};

export const deleteUser = async (id) => {
    const response = await axios.delete(configData.API_SERVER + `users/` + id, {
        headers: {
            'Authorization': JSON.parse(token)
        }
    });
    return response.data;
};

export const splitNumber = (number) => {
    // Check if the input is null or the string "null"
    if (number === null || number === "null") {
        return <span style={{ color: "red" }}>Missing</span>;
    }

    // Convert the number to a string
    let numStr = number.toString();

    // Initialize parts
    let part1 = "";
    let part2 = "";
    let part3 = "";
    let part4 = "";

    if (numStr) {
        // If the string starts with "216" and has length 10
        part1 = numStr.slice(0, 3);
        part2 = numStr.slice(3, 5);
        part3 = numStr.slice(5, 8);
        part4 = numStr.slice(8, 11);
        return `${(part1.includes('+') ? '(' : '(+') + part1 + ')'} ${part2} ${part3} ${part4}`;
    } else if (numStr.length === 8) {
        // If the string length is 7 (without "216")
        part2 = numStr.slice(2, 5);
        part3 = numStr.slice(5, 8);
        return `${part1} ${part2} ${part3}`;
    } else {
        return <span style={{ color: "red" }}>Missing</span>;
    }
}


export const handleDraft = async (name, values) => { const draftValues = { ...values }; localStorage.setItem(name, JSON.stringify(draftValues)); };

export const checkDraft = (draft) => { const data = localStorage.getItem(draft); return data !== null; };

export const isUserDataEmpty = (data) => { return Object.values(data).every(value => value === '' || value === 1) };


export const isNewStudents = async () => {
    const response = await axios({
        method: 'post',
        url: `${configData.API_SERVER}preselections/new`,
        headers: {
            'Authorization': `${JSON.parse(token)}`
        },

    })
    return response.data;
};